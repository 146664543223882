html, body {margin:0;padding:0;height:100%;scroll-behavior: smooth; background-color: black}

#root {
  display: contents;
}

#problem, #mission, #process, #methods {
    color: black;
}

:root {
    --dark-grey-background-color: #555555 ;
    --light-grey-background-color: #656565;
    --light-grey-text: #CBCBCB;

    --dark-green-background-color:#99f392 ;
    --medium-green-background-color:#A9FFA2;
    --light-green-background-color:#c8f5e4;
    --light-grey-text: #CBCBCB;

    --community-background: #303430;

    --orange-text-background: rgb(243, 175, 50);
}

.app {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.image-container {
    position: relative;
}

.image-wrapper {
    width: 100%;
    height: auto;
    margin-right: -5px;
    overflow: hidden;
}

#login-form-outer-container {
    background-image: url("/images/grouphouse-flower.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#registration-form-outer-container {
    background-image: url("/images/grouphouse-peaches.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-inner-container {
    background-color: white;
    width: 10em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3em;
    border-style: ridge;
}
.storyline-intro-image {
    background-image: url("/images/grouphouse-livingroom-with-person.jpg");
    margin: auto;
    background-size: cover;
    clear: both;
    background-position: 50% 50%;
    display: block;
    width: 100%;
    height: 800px;
    left: -8px;
    top: 70px;
}

.storyline-intro-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.storyline-intro-image-text {
    font-family: Montserrat;
    font-size: 3vw;
    color: #8afc80;
    font-weight: bold;
    -webkit-filter: blur(-10px);
    filter: blur(-10px);
    z-index: 1;
}

.storyline-intro-image-text-blur {
    background-color: black;
    opacity: 0.8;
    border-radius: 50px;
    -webkit-filter: blur(10px);
    filter: blur(10px);
    width: 36%;
    height: 8%;
}

/* Topbar Styles */

.topbar {
    display: flex;
    align-items: center;
    min-height: 3.5em;
    width: 100%;
    background-color: black;
}


.header-logo {
    float: left;
}

.header-logo-img {
    position: relative;
    width: 1.5em;
    margin: 10px;
    margin-left: 15px;
}

#login-icon-header {
    height:1.5em;
    width:auto;
    margin-right: .3em;
    margin-left: 0.3em;
}

#login-icon-view {
    height: 3em;
    width: auto;
}

.header-logo-title {
    font-family: Montserrat;
    float: left;
    left: 25px;
    color: white;
}

.header-links {
    margin-left: auto;
    display: inline;
    float: right;
}

.header-link {
    font-family: Montserrat;
    display: inline;
    text-decoration:none;
    color:white;
    font-weight: bold;
}

.header-link:hover {
    color:#e1a1db;
}

.header-dropdown {
    font-family: Montserrat;
}
ul{
    padding: 0;
    list-style: none;
}
ul li{
    display: inline-block;
    position: relative;
    line-height: 21px;
    text-align: left;
    padding-left: 15px;
    padding-right: 5px;
}
ul li a{
    display: block;
    padding: 15px 5px;
    color: white;
    text-decoration: none;
}
ul li a:hover{
    color: #e1a1db;
}
ul li ul.header-dropdown{
    min-width: 100%;
    background-color: rgba(0,0,0,0.85);
    display: none;
    position: absolute;
    z-index: 999;
    left: 0;
    margin-top: 12px;
}

ul li:hover ul.header-dropdown{
    display: block;
    z-index: 999;
}
ul li ul.header-dropdown li{
    display: block;
}

.embedded-link {
    text-decoration:underline;
    color:#e973dd;
}

.embedded-link:hover {
    color:white;
}

.header-emphasis {
    border: black;
    padding: 10px;
    border-style: solid;
    border-width: 2px;
    border-color: var(--dark-green-background-color);
    border-radius: 10px;
}

/* Storyline */

.storyline-container {
    width: 100%;
    background-color: var(--light-green-background-color);
    flex-direction: row;
}

.storyline-section-dark {
    min-height:25em;
    background-color: var(--dark-green-background-color);
    flex-direction: row;
    overflow: hidden;
    align-items: center;
    /* This is to fix odd 1px line between divs*/
    margin-bottom: -1px;
}

.storyline-section-light {
    min-height: 25em;
    overflow: hidden;
}

.storyline-section-grey {
    background-color: var(--community-background);
    overflow: hidden;
    padding-bottom: 2vw;
}

.storyline-image-right {
    width: 35%;
    margin: 2rem;
    margin-right: 4em;
    float: right;

}

.storyline-image-left {
    width: 35%;
    margin: 2rem;
    margin-left: 4em;
    float: left;
}


.storyline-section-title {
    font-family: Montserrat;
    margin: auto;
    width: 10%;
    text-align: center;
    font-size: 2.2vw;
    font-weight: bold;
}


.storyline-section-hook {
    font-family: Montserrat-Medium;
    color: black;
    font-size: 2.6vw;
    font-weight: bold;
    text-align: center;
    padding-top: 1rem;

}


.storyline-section-text-left {
    font-family: Montserrat-Regular;
    line-height: 1.7;
    color: black;
    width: 40%;
    margin-left: 10%;
    margin-top: 5rem;
    margin-bottom: auto;
    text-align: justify;
    float: left;
    font-size: 1.5vw;
    padding: 0.5em;
}

.storyline-section-text-right {
    font-family: Montserrat-Regular;
    line-height: 1.7;
    color: black;
    width: 40%;
    margin-right: 10%;
    margin-top: 5rem;
    text-align: justify;
    float: right;
    font-size: 1.5vw;
    padding: 0.5em;
}

.transition-block {
    min-height: 5em;
    background-color: var(--dark-green-background-color);
}

.transition-block-beginning {
    background-color: var(--medium-green-background-color);
    min-height: 7em;
}

.transition-skew-left-flush-bottom {
    background-color: var(--dark-green-background-color);
    clip-path: polygon(0 0,0% 100%, 100% 100%);
    min-height: 5em;
    margin-bottom:-1px;

}

.transition-skew-left-flush-top {
    background-color: var(--dark-green-background-color);
    clip-path: polygon(0 0, 100% 0%, 0% 100%);
    min-height: 5em;
    margin-bottom:-1px;

}


.transition-skew-right-flush-top {
    background-color: var(--dark-green-background-color);
    clip-path: polygon(0 0,0% 100%, 100% 0%);
    min-height: 5em;

}

.transition-skew-right-flush-bottom {
    background-color: var(--dark-green-background-color);
    clip-path: polygon(0 1,100% 100%, 100% 0%);
    min-height: 5em;
}

.storyline-section-transition {
    width: 100%;
    background-color: darkslateblue;
    height: 75px;
}

.community-section-transition {
    width: 100%;
    height: 100px;
    background-image: linear-gradient(var(--dark-green-background-color), var(--community-background));
}

/* Footer */

.footer-container {
    width: 100%;
    background-color: paleturquoise;
    min-height: 50px;
    bottom: 0;
    left: 0;
    width: 100%;
}

.footer-content {
    padding: 1rem;
}

.footer-links {
    float: right;
}

.footer-link{
    display: inline-flex;
    margin-left: 5px;

}

.footer-text {
    font-family: Montserrat;
    color: black;
    display: inline;
    margin-top: 5px;
}

.community-image-wrapper {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    height: auto;
    overflow: hidden;
}

.community-image {
    background-image:  url("/images/grouphouse-community.png");
    z-index: 0;
    background-size: cover;
    width: 100%;
    height: 60vw;
    left: -8px;
    box-shadow: 0 0 8px 8px var(--community-background) inset;
}


 /* Radial gradients in Corners for shadow effect */
.community-image-gradient-left {
    position: absolute;
    z-index: 2;
    height: 60vw;
    width: 80%;
    background-image: radial-gradient(circle at top left, rgba(48, 52, 48, 1), transparent);
}

.community-image-gradient-right {
    position: absolute;
    right: 10%;
    z-index: 2;
    height: 60vw;
    width: 80%;
    background-image: radial-gradient(circle at top right, rgba(48, 52, 48, 1), transparent);
}

.community-image-triangle-left {
    position: absolute;
    width: 0;
    height: 0;
}

.community-image-triangle-right {
    position: absolute;
    right: 10%;
    width: 0;
    height: 0;
}
.triangle-1-left {
    z-index: 2;
    border-top: 5vw solid var(--community-background);
    border-right: 5vw solid transparent;
    opacity: 1;
}
.triangle-2-left {
    z-index: 3;
    border-top: 10vw solid var(--community-background);
    border-right: 10vw solid transparent;
    opacity: 0.9;
}
.triangle-3-left {
    z-index: 4;
    border-top: 15vw solid var(--community-background);
    border-right: 15vw solid transparent;
    opacity: 0.8;
}
.triangle-4-left {
    z-index: 5;
    border-top: 20vw solid var(--community-background);
    border-right: 20vw solid transparent;
    opacity: 0.7;
}
.triangle-5-left {
    z-index: 6;
    border-top: 25vw solid var(--community-background);
    border-right: 25vw solid transparent;
    opacity: 0.6;
}
.triangle-6-left {
    z-index: 7;
    border-top: 30vw solid var(--community-background);
    border-right: 30vw solid transparent;
    opacity: 0.5;
}
.triangle-7-left {
    z-index: 8;
    border-top: 35vw solid var(--community-background);
    border-right: 35vw solid transparent;
    opacity: 0.4;
}
.triangle-8-left {
    z-index: 9;
    border-top: 40vw solid var(--community-background);
    border-right: 40vw solid transparent;
    opacity: 0.3;
}
.triangle-9-left {
    z-index: 10;
    border-top: 45vw solid var(--community-background);
    border-right: 45vw solid transparent;
    opacity: 0.2;
}
.triangle-10-left {
    z-index: 11;
    border-top: 50vw solid var(--community-background);
    border-right: 50vw solid transparent;
    opacity: 0.2;
}
.triangle-11-left {
    z-index: 12;
    border-top: 55vw solid var(--community-background);
    border-right: 55vw solid transparent;
    opacity: 0.2;
}
.triangle-12-left {
    z-index: 13;
    border-top: 60vw solid var(--community-background);
    border-right: 60vw solid transparent;
    opacity: 0.2;
}
.triangle-13-left {
    z-index: 14;
    border-top: 65vw solid var(--community-background);
    border-right: 65vw solid transparent;
    opacity: 0.2;
}

.triangle-1-right {
    z-index: 2;
    border-top: 5vw solid var(--community-background);
    border-left: 5vw solid transparent;
    opacity: 1;
}
.triangle-2-right {
    z-index: 3;
    border-top: 10vw solid var(--community-background);
    border-left: 10vw solid transparent;
    opacity: 0.9;
}
.triangle-3-right {
    z-index: 4;
    border-top: 15vw solid var(--community-background);
    border-left: 15vw solid transparent;
    opacity: 0.8;
}
.triangle-4-right {
    z-index: 5;
    border-top: 20vw solid var(--community-background);
    border-left: 20vw solid transparent;
    opacity: 0.7;
}
.triangle-5-right {
    z-index: 6;
    border-top: 25vw solid var(--community-background);
    border-left: 25vw solid transparent;
    opacity: 0.6;
}
.triangle-6-right {
    z-index: 7;
    border-top: 30vw solid var(--community-background);
    border-left: 30vw solid transparent;
    opacity: 0.5;
}
.triangle-7-right {
    z-index: 8;
    border-top: 35vw solid var(--community-background);
    border-left: 35vw solid transparent;
    opacity: 0.4;
}
.triangle-8-right {
    z-index: 9;
    border-top: 40vw solid var(--community-background);
    border-left: 40vw solid transparent;
    opacity: 0.3;
}
.triangle-9-right {
    z-index: 10;
    border-top: 45vw solid var(--community-background);
    border-left: 45vw solid transparent;
    opacity: 0.2;
}
.triangle-10-right {
    z-index: 11;
    border-top: 50vw solid var(--community-background);
    border-left: 50vw solid transparent;
    opacity: 0.2;
}
.triangle-11-right {
    z-index: 12;
    border-top: 55vw solid var(--community-background);
    border-left: 55vw solid transparent;
    opacity: 0.2;
}
.triangle-12-right {
    z-index: 13;
    border-top: 60vw solid var(--community-background);
    border-left: 60vw solid transparent;
    opacity: 0.2;
}
.triangle-13-right {
    z-index: 14;
    border-top: 65vw solid var(--community-background);
    border-left: 65vw solid transparent;
    opacity: 0.2;
}

/* Community */

.community-image-text-blur {
    background-color: black;
    opacity: 0.68;
    border-radius: 500px;
    -webkit-filter: blur(10px);
    filter: blur(10px);
    width: 45%;
    height: 20%;
}

.community-centered {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.community-buttons-centered {
    z-index: 50;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.community-section-title {
    font-family: Montserrat;
    font-size: 2.2vw;
    margin-top: 6vw;
}

.community-image-text {
    text-align: center;
    font-size: 1.8vw;
    z-index: 15;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Montserrat;
    color: #d45a9d;
    font-weight: bold;
}

.community-section-hook {
    font-family: 'Montserrat-Medium';
    font-size: 2.5vw;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.community-buttons {
    text-align: center;
    width: 100%;
    z-index: 15;
}

.community-button {
    font-family: Montserrat;

    display: inline-block;
    font-size: 2.7vw;
    padding: 0.5vw;
    margin: 0.5vw;
    margin-bottom: 1vw;
    height: 3.5vw;
    width: 20vw;
    background-color: #c851ac;
    border-radius: 1.5vw;
    color: rgb(243 175 50);
    border: solid;
    border-color: 3px;
    color: #20ba88;
}

.community-button:hover {
    background-color: #DE62CEFF;
}

.community-link {
    text-decoration: none;
    font-family: Montserrat-Medium;
    color: #0bc88a;
}


/* Hiding topbar for mobile screens */
@media only screen and (max-width: 800px) {
    .storyline-intro-image {
        display: none;
    }

    .storyline-intro-image-text {
        display: none;
    }

    .intro-image-container {
        display: none;
    }
    .topbar {
        min-height: 3em;
        width: 100%;
        background-color: black;
    }
    .header-logo-title {
        font-family: Montserrat;
        font-size: 3rem;
        width: 100%;
        text-align: center;
        color: var(--light-grey-text)
    }
    .header-links {
        display: none;
    }

    .header-logo {
        display: none;
    }

    .storyline-section-dark {
        min-height: 30em;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        overflow: hidden;
    }
    .storyline-section-light {
        min-height: 30em;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        overflow: hidden;
    }
    .storyline-section-content {
        display: flex;
        justify-content: space-around;
    }
    .storyline-section-text-left {
        font-family: Montserrat;
        color: black;
        font-size: 1.5rem;
        width: 92%;
        padding: 0.5em;
        margin: 0.5em;
        font-weight: bold;
    }
    .storyline-section-text-right {
        font-family: Montserrat;
        color: black;
        font-size: 1.5rem;
        width: 92%;
        padding: 0.5em;
        margin: 0.5em;
        font-weight: bold;

    }
    .storyline-image-right {
        width: 80%;
        padding-left: 2rem;
        padding-right: 2rem;
        display: block;
        margin-top: .5em;
        margin-bottom: .5em;
        margin-left: auto;
        margin-right: auto;

    }
    .storyline-image-left {
        width: 70%;
        padding-left: 2rem;
        padding-right: 2rem;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: .5em;
        margin-bottom: .5em;

    }
    .storyline-section-title {
        font-family: Montserrat;
        margin: auto;
        width: 100%;
        text-align: center;
        font-size: 3rem;
        font-weight: bold;
    }

    .storyline-section-hook {
        color: black;
        font-size: 2rem;
        width: 80%;
        margin: auto;
        text-align: center;
        padding-top: 1rem;

    }
    .community-image-wrapper {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        height: auto;
        overflow: hidden;
    }
}

